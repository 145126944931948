/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Sponsors = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    var $templateSponsorsItem;
    var $templateSponsorsSpeaker;

    function Init() {

        if (localState.sponsors == undefined) {
            localState.sponsors = {
                itemIndex: 0
            }
        }

        $templateSponsorsItem = $('template#tplSponsorsItem').contents().remove();
        $templateSponsorsSpeaker = $('template#tplSponsorSpeaker').contents().remove();
        render();
        renderDetails();
        $("#sponsorsIframeId").unbind("load");
        $(document).on('click', '#sponsors .link', clickSponsors);
        $(document).on('click', '#sponsors-details .sponsor-users .item', clickSponsorUser)
        .on('click', '#sponsors-details .sponsor-networking button', function (){
            Weblinks.showDetails("45570664");
        })
        .on('click', '#sponsors-details .favorite.save', evtClickFavorite)
        .on('click', '#sponsors-details .favorite.cancel', evtClickCancelFavorite)
        .on('click', "#sponsors-details .sponsor-website", clickSponsorFeature)
        .on('click', "#sponsors-details .sponsor-linkedin", clickSponsorFeature);
        if ('search' in localState.sponsors) {
            $('#sponsors .search-wrapper input').val(localState.sponsors.search).trigger('input');
        }
    }

    function clickSponsorFeature(data) {

        var class2 = $(this).attr("class"),
            feature,
            sponsor_id,
            speaker_id;

        if(class2 !== undefined) {
            feature = $(this).attr("class");
            sponsor_id = $(this).attr("data-id");
        } else {
            feature    = data['feature'];
            sponsor_id = data['sponsor_id'];
            speaker_id = data['speaker_id'];
        }

        $.post("api/", {
            'do' : 'addSponsorFeature',
            'sponsor_id': sponsor_id,
            'feature' : feature,
            'speaker_id' : speaker_id
        }, function(data) {
            // console.log("data", data);
        });
    }

    function sort() {
        AppData.sponsors.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });

        AppData.sponsorgroups.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function render() {
        sort();
        var
            $self = $('#sponsors .sponsorsList'),
            $fragmentSponsors = $(document.createDocumentFragment());

        var nogroups = [];

        if (AppData.sponsorgroups.length == 0) {
            nogroups = AppData.sponsors;
        }

        $.each(AppData.sponsorgroups, function (gi, gel) {

            var in_group_count = 0;

            var grouptitle = $("<h3/>").addClass("grouptitle").css({
                color: gel.titlecolor || "#555555"
            }).html(gel.title[localState.lang]);
            grouptitle.appendTo($fragmentSponsors);

            var $fragmentSponsorsList = $(document.createDocumentFragment());
            var linkcont = $("<div/>").addClass("sponsors_cont").appendTo($fragmentSponsorsList);

            $.each(AppData.sponsors, function (i, el) {

                var inarray = nogroups.find(function (ng) {
                    return ng.id == el.id;
                });

                if ((el.group == "" || el.group == undefined) && inarray == undefined) {
                    nogroups.push(el);
                }

                if (el.group == gel.id && el.active) {

                    in_group_count++;

                    var $clone = $templateSponsorsItem.clone();
                    $clone.find('.data-title').text(el.name[localState.lang]);
                    $clone.find('.data-desc').text(el.description[localState.lang]);
                    $clone.find('.data-building').text(el.building[localState.lang]);

                    $clone.find('.data-desc').toggleClass("hidden", el.description[localState.lang] == "");
                    $clone.find('.data-building').toggleClass("hidden", el.building[localState.lang] == "");

                    $clone.toggleClass("link", el.link != "");

                    $clone.attr('data-id', el.id);
                    $clone.attr('data-blank', el.open_in_blank);
                    $clone.appendTo(linkcont);
                    if (el.file) {
                        $clone.find('.data-image').attr("src", el.file.path);
                        if (gel.imgsize === "increased") {
                            $clone.find('.data-image').addClass("increased");
                        }
                    }

                    //$clone.find('.data-title').hide();
                    $clone.find('.data-image').show();
                    $clone.find('.fa-sticky-note').hide();
                }
            });

            $fragmentSponsorsList.appendTo($fragmentSponsors);

            if (in_group_count == 0) {
                grouptitle.remove();
            }
        });

        if (nogroups.length > 0) {

            var $fragmentSponsorsList = $(document.createDocumentFragment());
            var linkcont = $("<div/>").addClass("sponsors_cont").appendTo($fragmentSponsorsList);

            var grouptitle = $("<h3/>").addClass("grouptitle").html("&nbsp;");
            grouptitle.appendTo($fragmentSponsors);

            $.each(nogroups, function (i, el) {
                if(el.active) {
                    var $clone = $templateSponsorsItem.clone();
                    $clone.find('.data-title').text(el.name[localState.lang]);
                    $clone.find('.data-desc').text(el.description[localState.lang]);
                    $clone.find('.data-building').text(el.building[localState.lang]);

                    $clone.find('.data-desc').toggleClass("hidden", el.description[localState.lang] == "");
                    $clone.find('.data-building').toggleClass("hidden", el.building[localState.lang] == "");

                    $clone.toggleClass("link", el.link != "");

                    $clone.attr('data-id', el.id);
                    $clone.attr('data-blank', el.open_in_blank);
                    $clone.appendTo(linkcont);
                    if (el.file) {
                        $clone.find('.data-image').attr("src", el.file.path);
                    }

                    //$clone.find('.data-title').hide();
                    $clone.find('.data-image').show();
                    $clone.find('.fa-sticky-note').hide();
                }
            });

            $fragmentSponsorsList.appendTo($fragmentSponsors);
        }

        $self.html($fragmentSponsors);
        $('#sponsors').find('.no-result').toggle(Boolean(!AppData.sponsors.length));
    }

    function clickSponsors() {

        var id = $(this).attr("data-id");

        var sponsor = AppData.sponsors.find(function (item) {
            return item.id == id;
        });

        if (typeof window.webkit !== "undefined") {
            window.webkit.messageHandlers.openUrl.postMessage(sponsor.link);
            return;
        }

        if (typeof Android !== "undefined") {
            Android.openUrl(sponsor.link);
            return;
        }

        window.open(sponsor.link);

        /*
            localState.sponsors.itemIndex = id;
            Layout.hide('sponsors-details', true);
            showDetails(id);
            Layout.show("sponsors-details");
        */
    }

    function clickSponsorUser() {
        var id = $(this).attr("data-index");
        var utype = $(this).attr("data-utype");
        clickSponsorFeature({"feature" : "show-sponsor-speaker", "sponsor_id": $(this).attr("data-sponsor_id"), "speaker_id": id});

        if (utype == "speaker") {
            //Speakers.evtClickDetails();
            Layout.hide('speakers-details', true);
            Speakers.showDetails(id);
        } else {
            Layout.hide('attendees-details', true);
            Attendees.showDetails(id);
        }
    }

    function renderDetails() {
        var sponsor = AppData.sponsors.find(function (e) {
                return localState.sponsors.itemIndex == e.id;
            }),
            item = AppData.favorites.find(function (item) {
                return item.type === 'sponsor' && item.data === localState.sponsors.itemIndex
            }),
            saved = Boolean(item),
            undo  = Boolean(item) && !item.sent;
        if (sponsor != undefined) {

            if (sponsor.file) {
                $("#sponsors-details .sponsor-img").attr("src", sponsor.file.path);
                $("#sponsors-details .sponsor-img-cont").show();
            } else {
                $("#sponsors-details .sponsor-img-cont").hide();
            }
            $("#sponsors-details .sponsor-title").html(sponsor.name[localState.lang]);
            $("#sponsors-details .sponsor-website a").attr("href", sponsor.link);
            $("#sponsors-details .sponsor-website").attr("data-id", sponsor.id);
            $("#sponsors-details .sponsor-linkedin a").attr("href", sponsor.linkedin_link);
            $("#sponsors-details .sponsor-linkedin").attr("data-id", sponsor.id);
            if (sponsor.networking_link != "" && sponsor.networking_link != undefined) {
                //$("#sponsors-details .sponsor-networking a").attr("href", sponsor.networking_link);
                $("#sponsors-details .sponsor-networking").show();
            } else {
                $("#sponsors-details .sponsor-networking").hide();
            }
            $("#sponsors-details .sponsor-description").html(nl2br(sponsor.description[localState.lang]));

            var sponsor_users = AppData.attendees.filter(function (item) {
                return item.sponsor == sponsor.id;
            });

            var sponsor_speakers = AppData.speakers.filter(function (item) {
                return item.sponsor == sponsor.id;
            });

            sponsor_users = sponsor_users.concat(sponsor_speakers);

            $("#sponsors-details .sponsor-users").html("");

            //interests
            if (typeof sponsor.solutions !== "undefined" && sponsor.solutions[localState.lang].length) {
                var html = '<strong>' + AppData.languages[localState.lang]["profile-interests"] + '</strong><ul class="sponsorinterests">';
                sponsor.solutions[localState.lang].forEach(function (i) {
                    if (AppData.select_fields["SOLUTIONS"][localState.lang][i])
                        html += '<li>' + AppData.select_fields["SOLUTIONS"][localState.lang][i] + '</li>';
                });
                html += '</ul>';
                $("#sponsors-details").find('.data-interests').show();
                $("#sponsors-details").find('.data-interests>span').html(html);
                sortListUl(".sponsorinterests");
            } else {
                $("#sponsors-details").find('.data-interests').hide();
            }

            for (var i = 0; i < sponsor_users.length; i++) {
                var $clone = $templateSponsorsSpeaker.clone();

                $clone.attr("data-index", sponsor_users[i].id).attr("data-utype", sponsor_users[i].group);
                $clone.attr("data-sponsor_id", sponsor.id);
                $clone.find('.data-name').text(sponsor_users[i].name[localState.lang]);
                $clone.find('.data-job').text(sponsor_users[i].job[localState.lang]);
                $clone.find('.data-img').attr('src', (sponsor_users[i].img) ? 'upload/users/' + sponsor_users[i].img : 'img/profile-picture-placeholder.png');

                /*var uitem = $clone.addClass("item link flex sponsor-user").attr("data-index", sponsor_users[i].id);
                uitem.html('<div>'+sponsor_users[i].name[localState.lang]+'</div>');
                uitem.append('<div class="chevron flex-min-content"><i class="fas fa-chevron-right"></i></div>');*/
                $("#sponsors-details .sponsor-users").append($clone);
            }
            var $sponsor = $('#sponsors-details article');
            $sponsor.find('.favorite.save').toggleClass('hidden', saved);
            $sponsor.find('.favorite.saved').toggleClass('hidden', !saved);
            $sponsor.find('.favorite.cancel').toggleClass('hidden', !undo);
        }
    }

    function showDetails(id) {

        localState.sponsors = {
            itemIndex: id
        };
        renderDetails();
        Layout.show("sponsors-details");
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function update() {
        $.post('api/', {
            do: 'getSponsors'
        }, function (data) {
            AppData.sponsors = data;
            render();
            data = null;
        });
    }

    function updateGroups() {
        $.post('api/', {
            do: 'getSponsorgroups'
        }, function (data) {
            AppData.sponsorgroups = data;
            render();
            data = null;
        });
    }

    function evtClear(e) {
        e.preventDefault();
        var $input = $('#sponsors .search-wrapper input');
        $input.val('').trigger('input');
    }

    /* ----- Event: Press Enter --------------------------------------------------------------------------------------- */
    function evtPressEnter(e) {
        if (e.wich || e.keyCode === 13) {
            this.blur();
        }
    }

    /* ----- Event: Input on Search ----------------------------------------------------------------------------------- */
    function evtInput(e) {
        var
            isResult = false,
            $btnClear = $('#sponsors .search-wrapper .btn-clear'),
            $noResult = $('#sponsors .no-result'),
            value = $.trim($('#sponsors .search-wrapper input').val()),
            search = value.split(' ').join('|'),
            regex1 = new RegExp(strCanonical(search), 'i'),
            regex2 = new RegExp(strCanonical(search), 'i'),
            regex3 = new RegExp(strCanonical(search), 'i'),
            regex4 = new RegExp(strCanonical(search), 'i'),

            isMatching;
        localState.sponsors.search = value;

        $('#sponsors .sponsorsList').find(".sponsors_cont .link").hide();
        $.each(AppData.sponsors, function (i, e) {
            isMatching = false;
            if(e.name !== undefined) {
                $.each(e.name, function (i, e) {
                    e = strCanonical(e.split(' ').join(''));
                    isMatching = regex1.test(e) || isMatching;
                });
            }
            if(e.description !== undefined) {
                $.each(e.description, function (i, e) {
                    e = strCanonical(e.split(' ').join(''));
                    isMatching = regex2.test(e) || isMatching;
                });
            }
            if(e.solutions !== undefined) {
                $.each(e.solutions, function (i, e) {
                    var lang = i;
                    $.each(e, function (i, e2) {
                        let ex = strCanonical(AppData.select_fields["SOLUTIONS"][lang][e2].split(' ').join(''));
                        isMatching = regex3.test(ex) || isMatching;
                    });
                });
            }

            var sponsor_speakers = AppData.speakers.filter(function (item) {
                return item.sponsor == e.id;
            });

            if(sponsor_speakers.length > 0) { // Külön szedett név
                $.each(sponsor_speakers, function (i, e) {
                    var lang = i;
                    $.each(e.name, function (i, e2) {
                        e2 = strCanonical(e2.split(' ').join(''));
                        isMatching = regex4.test(e2) || isMatching;
                        var speakers_agendas = AppData.agenda.filter(function (item) {
                            if(item.speakers !== undefined && item.speakers !== "") {
                                var speakers = item.speakers.filter(function (s) {
                                    return s.speaker_id === e.id;
                                });
                                return Boolean(speakers.length);
                            } else {
                                return false;
                            }
                        });
                        if(speakers_agendas.length > 0) {
                            $.each(speakers_agendas, function (i, e) {
                                var lang = i;
                                $.each(e.title, function (i, e2) {
                                    e2 = strCanonical(e2.split(' ').join(''));
                                    isMatching = regex4.test(e2) || isMatching;
                                });
                                var lang = i;
                                $.each(e.details, function (i, e2) {
                                    e2 = strCanonical(e2.split(' ').join(''));
                                    isMatching = regex4.test(e2) || isMatching;
                                });
                            });
                        }
                    });
                });
            }

            if(isMatching) {
                $('#sponsors .sponsorsList').find(".sponsors_cont .link").each(function (i, el) {
                    if(el.dataset.id === e.id) {
                        $(el).show();
                    }
                });
            }
            isResult = (isMatching) ? true : isResult;
        });
        $btnClear.toggle(Boolean(value.length));
        $noResult.toggle(!isResult);
    }

    function evtClickFavorite() {
        var $self = $('#sponsors-details');
        $self.find('.favorite.save').addClass('hidden');
        $self.find('.favorite.saved').removeClass('hidden');
        $self.find('.favorite.cancel').removeClass('hidden');
        $.post('api/', {
            do: 'saveFavoriteSponsor',
            data: {'id' : localState.sponsors.itemIndex || false, 'lang': localState.lang}
        }, function (res) {
            AppData.favorites = res;
            BookedAgenda.renderList();
            Modal.resultconfirmation(
                {"canClose": true,
                    "title": AppData.languages[localState.lang].businesscardrequest_title,
                    "text":  AppData.languages[localState.lang].businesscardrequest_text
                }
            );
            res = null;
        });
        $self = null;
    }

    function evtClickCancelFavorite() {
        var
            $self = $('#sponsors-details'),
            saved = AppData.favorites.find(function (item) {
                return item.type === 'sponsor' && item.data === localState.sponsors.itemIndex
            });
        $self.find('.favorite.save').removeClass('hidden');
        $self.find('.favorite.saved').addClass('hidden');
        $self.find('.favorite.cancel').addClass('hidden');
        $.post('api/', {
            do: 'removeFavorite',
            data: saved.id
        }, function (res) {
            AppData.favorites = res;
            BookedAgenda.renderList();
            res = null;
        });
        $self = null;
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        render: render,
        showDetails:showDetails,
        update: update,
        updateGroups: updateGroups,
        clickSponsors: clickSponsors
    }
})();
