/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Weblinks = (function () {
    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    let $templateWeblinksItem;
    let loadtimer;
    let allow_load = true;
    let last_groupfilter;

    function Init() {
        $templateWeblinksItem = $('template#tplWeblinksItem').contents().remove();
        render();
        $("#weblinksIframeId").unbind("load");
        $(document).on('click', '#weblinks .item', clickWeblinks);
    }

    function sort() {
        AppData.weblinks.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });

        AppData.weblinkgroups.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    /* ----- Show weblink ------------------------------------------------------------------------------------------- */
    function clickWeblinks(e) {
        var link, open_in_blank, open_in_landing, is_hidden;

        if(e.link != undefined) {
            link = e.link;
        } else {
            link = $(this).attr("data-link");
        }

        if (typeof window.webkit !== "undefined") {
            window.webkit.messageHandlers.openUrl.postMessage(link);
            return;
        }

        if (typeof Android !== "undefined") {
            Android.openUrl(sponsor.link);
            return;
        }

        if (link.indexOf("{{hash}}") > -1) {
            link = link.replace("{{hash}}", AppData.profile.hash);
        }

        if (e.open_in_blank != undefined) {
            open_in_blank = e.open_in_blank;
        } else {
            open_in_blank = $(this).attr("data-blank");
        }

        if (e.open_in_landing != undefined) {
            open_in_landing = e.open_in_landing;
        } else {
            open_in_landing = $(this).attr("data-landing");
        }

        if (open_in_landing == "1") {

            if (e.is_hidden != undefined) {
                is_hidden = e.is_hidden;
            } else {
                is_hidden = $(this).attr("data-hidden");
            }

            $("#viewer360").unbind("load").bind("load", function () {
                if(e.show_close_button_on_landing === "1") {
                    $('#landing .landing_elements .closeweblink_btn').show();
                } else {
                    closeWeblinkIframe();
                }
            });

            if (!$(".landing_elements .viewer360 #viewer360").is(":visible")) {
                $(".landing_elements .viewer360 #viewer360").attr("src", link);
            }
            $(".landing_elements .viewer360").addClass("shown");

            if (is_hidden == "1") {
                $(".landing_elements .viewer360").css("flex", "0");
            } else {
                $(".landing_elements .viewer360").css("flex", "");
            }
            return;
        }

        if (open_in_blank == "1") {
            window.open(link);
            return;
        }

        //$(".cover", $("#weblinks")).show();
        //loadtimerStart();
        allow_load = true;
        writeDbLog("open link", link, "user_logs", "weblinks");
        $("#weblinksIframeId").attr("src", link);
        Layout.show("weblinks-details");

        // $("#weblinksIframeId").unbind("load").on("load", function () {
            /*$(".cover", $("#weblinks")).hide();
            clearTimeout(loadtimer);
            if (allow_load) {
                Layout.show("weblinks-details");
                var wiframeindex = -1;
                for(var i in localState.layout) {
                    if (localState.layout[i] === "weblinks-details") {
                        wiframeindex = i;
                    }
                }
                if (wiframeindex > -1) {
                    localState.layout.splice(wiframeindex, 1);
                }
            }*/
        // });

    }

    function closeWeblinkIframe() {
        $(".landing_elements .viewer360").removeClass("shown");
        $(".landing_elements .viewer360 #viewer360").attr("src", "");
        $("#landing .landing_elements .closeweblink_btn").hide();
    }

    function loadtimerStart() {
        loadtimer = setTimeout(function () {
            $(".cover", $("#weblinks")).hide();
            allow_load = false;
            Layout.hide("weblinks-details");
        }, 10000);
    }

    function render(groupfilter) {
        sort();
        var
            $self = $('#weblinks .weblinksList'),
            $fragmentWeblinks = $(document.createDocumentFragment());

        var nogroups = [];

        if (AppData.weblinkgroups.length == 0) {
            nogroups = AppData.weblinks;
        }

        var groups = AppData.weblinkgroups;

        if (groupfilter != undefined) {
            groups = AppData.weblinkgroups.filter(function (el) {
                return $.inArray(el.id, groupfilter) > -1;
            });
        }

        last_groupfilter = groupfilter;

        $.each(groups, function (gi, gel) {

            var in_group_count = 0;

            var grouptitle = $("<h3/>").addClass("grouptitle").css({
                color: gel.titlecolor || "#555555"
            }).html(gel.title[localState.lang]);
            grouptitle.appendTo($fragmentWeblinks);

            $.each(AppData.weblinks, function (i, el) {

                var inarray = nogroups.find(function (ng) {
                    return ng.id == el.id;
                });

                if ((el.group == "" || el.group == undefined) && inarray == undefined) {
                    nogroups.push(el);
                }

                if (el.group == gel.id && el.active) {

                    in_group_count++;

                    var $clone = $templateWeblinksItem.clone();
                    $clone.find('.data-title').html(el.name[localState.lang]);
                    $clone.attr('data-link', el.link);
                    $clone.attr('data-blank', el.open_in_blank);
                    $clone.attr('data-landing', el.open_in_landing);
                    $clone.attr('data-hidden', el.is_hidden);
                    $clone.appendTo($fragmentWeblinks);
                    if (el.file) {
                        $clone.find('.data-image').attr("src", el.file.path);
                    }
                    if (el.show_image == 1) {
                        if (gel.alignment) {
                            $clone.find(".info").css("text-align", gel.alignment);
                        }
                        $clone.find('.data-title').hide();
                        $clone.find('.data-image').show();
                        if (gel.logo_height) {
                            $clone.find(".data-image").css("max-height", gel.logo_height+"px");
                        }
                        $clone.find('.fa-sticky-note').hide();
                    } else {
                        $clone.find('.data-title').show();
                        $clone.find('.data-image').hide();
                        $clone.find('.fa-sticky-note').show();
                    }
                }
            });

            if (in_group_count == 0) {
                grouptitle.remove();
            }
        });

        if (nogroups.length > 0) {

            var grouptitle = $("<h3/>").addClass("grouptitle").html("&nbsp;");
            grouptitle.appendTo($fragmentWeblinks);

            $.each(nogroups, function (i, el) {
                if(el.active) {
                    var $clone = $templateWeblinksItem.clone();
                    $clone.find('.data-title').html(el.name[localState.lang]);
                    $clone.attr('data-link', el.link);
                    $clone.attr('data-blank', el.open_in_blank);
                    $clone.attr('data-landing', el.open_in_landing);
                    $clone.attr('data-hidden', el.is_hidden);
                    $clone.appendTo($fragmentWeblinks);
                    if (el.file) {
                        $clone.find('.data-image').attr("src", el.file.path);
                    }
                    if (el.show_image == 1) {
                        //$clone.find(".info").addClass("text-center");
                        $clone.find('.data-title').hide();
                        $clone.find('.data-image').show();
                        $clone.find('.fa-sticky-note').hide();
                    } else {
                        $clone.find('.data-title').show();
                        $clone.find('.data-image').hide();
                        $clone.find('.fa-sticky-note').show();
                    }
                }
            });
        }

        $self.html($fragmentWeblinks);
        $('#weblinks').find('.no-result').toggle(Boolean(!AppData.weblinks.length));
    }

    function showDetails(id) {

        if (id == 'all_links') {
            var group_ids = AppData.weblinkgroups.map(function (el) {
                return el.id;
            });

            render(group_ids);
            Layout.show("weblinks");
        } else {
            var link = AppData.weblinks.find(function (e) {
                return id == e.id;
            });
            if (link != undefined) {
                if (link.open_in_blank != "1" && link.open_in_landing != "1") {
                    //$('#weblinks').addClass('animated faster fadeInRight').show();
                    //$('#weblinks').find(".container .whitebg").show();
                    //$('#weblinks').find(".container .cover").show();
                }
                clickWeblinks(link);
                if (link.open_in_blank != "1" && link.open_in_landing != "1") {
                    /*setTimeout(function () {
                        $('#weblinks').find(".container .whitebg").hide();
                        $('#weblinks').hide();
                    }, 2000);*/
                }
            } else {
                var group = AppData.weblinkgroups.find(function (el) {
                    return id == el.id;
                });
                if (group != undefined) {
                    render([group.id]);
                    Layout.show("weblinks");
                }
            }
        }

    }

    function checkCloseBtn(e) {
        $.post('api/', {
            do: 'getWeblinks'
        }, function (data) {
            AppData.weblinks = data;
            e = AppData.weblinks.filter(function(i) {
                return i.id === e;
            })[0];
            if(e.show_close_button_on_landing === "1") {
                $('#landing .landing_elements .closeweblink_btn').show();
            } else {
                $("#landing .landing_elements .closeweblink_btn").hide();
            }
            data = null;
        });
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function update(e) {
        $.post('api/', {
            do: 'getWeblinks'
        }, function (data) {
            AppData.weblinks = data;
            render(last_groupfilter);
            data = null;
        });
    }

    function updateGroups() {
        $.post('api/', {
            do: 'getWeblinkgroups'
        }, function (data) {
            AppData.weblinkgroups = data;
            render(last_groupfilter);
            data = null;
        });
    }

    function showPortal(url) {
        $('#weblinks-details #stand_viewer').attr('src', url);
        $('#weblinks-details .stand_viewer').css("display", "flex").hide().fadeIn(400, function () {});
    }

    $(document).on('click', '#weblinks-details .close_stand', function (e) {
        $('#weblinks-details #stand_viewer').attr('src', '');
        $('#weblinks-details .stand_viewer').hide();
    });

    $(document).on('click', '.landing_elements .closeweblink_btn', function (e) {
        $(".landing_elements .viewer360").toggleClass("shown");
        $(".landing_elements .closeweblink_btn").hide();
        $(".landing_elements .viewer360 #viewer360").attr('src', '');
    });

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        render: render,
        showDetails:showDetails,
        update: update,
        updateGroups: updateGroups,
        showPortal: showPortal,
        loadtimer: loadtimer,
        checkCloseBtn: checkCloseBtn,
        closeWeblinkIframe: closeWeblinkIframe
    }
})();
